import API from "@/api/user";

// Initial state
const state = {
  auth_user: {},
};

// Getters
const getters = {
  authUser: (state) => state.auth_user,
};

// Actions
const actions = {
  setAuthUser: ({ commit }, user) => {
    commit("SET_AUTH_USER", user);
  },

  updateUser({ commit }, formData) {
    return new Promise((resolve, reject) => {
      API.update(formData, (response) => {
        if (response.status !== 200) {
          reject(response.data.errors);
          return;
        }

        commit("SET_AUTH_USER", response.data.data);
        resolve("Success");
      });
    });
  },

  requestDeletion({ commit }) {
    return new Promise((resolve, reject) => {
      API.requestDeletion((response) => {
        if (response.status !== 200) {
          reject(response.data.errors);
          return;
        }

        commit("SET_AUTH_USER", response.data.data);
        resolve("Success");
      });
    });
  },
};

// Mutations
const mutations = {
  SET_AUTH_USER(state, user) {
    state.auth_user = user;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
