import {
  setState,
  setGetters,
  setActions,
  setMutations,
} from "../extends/crud_table";
import transactions_api from "@/api/transactions";

// Initial state
const state = {
  ...setState(),
};

// Getters
const getters = {
  ...setGetters(),
  transactionsGraph: (state) => state.transactions_graph,
};

const actions = {
  ...setActions(transactions_api),
};

const mutations = {
  ...setMutations(),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
