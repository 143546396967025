/*eslint no-empty-pattern: "off"*/

/**
 * Table.vue component global configurations.
 */

// Set state options.
export const setState = () => ({
  records: [],
  record: {},
  meta: {},
  fetch_config: {},
});

// Set getter options.
export const setGetters = () => ({
  records: (state) => state.records,
  record: (state) => state.record,
  meta: (state) => state.meta,
  fetchConfig: (state) => state.fetch_config,
  hasFilters: (state) =>
    state.meta && state.meta.last_page && state.meta.last_page > 1,
});

// Set action options.
export const setActions = (API) => ({
  setupConfigHard({ commit }, config) {
    commit("SETUP_FETCH_CONFIG_HARD", config);
  },

  setupConfig({ commit }, config) {
    commit("SETUP_FETCH_CONFIG", config);
  },

  fetchRecords({ commit, state }) {
    return new Promise((resolve, reject) => {
      API.fetch(state.fetch_config, (response) => {
        if (response.status !== 200) {
          // #TODO - Handle errors
          console.log("Error", response);
          reject("Fail");
          return;
        }
        commit("SET_RECORDS", response.data.data);
        commit("SET_META", response.data.meta);
        resolve("Success");
      });
    });
  },

  fetchSingle({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.fetchOne(id, (response) => {
        if (response.status !== 200) {
          reject("Fail");
          return;
        }

        commit("SET_RECORD", response.data.data);
        resolve("Success");
      });
    });
  },

  createRecord({}, formData) {
    return new Promise((resolve, reject) => {
      API.create(formData, (response) => {
        if (response.status !== 201) {
          reject(response.data.errors);
          return;
        }

        resolve("Success");
      });
    });
  },

  updateRecord({}, formData) {
    const recordID = formData.get("id");

    return new Promise((resolve, reject) => {
      API.update(recordID, formData, (response) => {
        if (response.status !== 200) {
          reject(response.data.errors);
          return;
        }

        resolve("Success");
      });
    });
  },

  deleteRecord({}, id) {
    return new Promise((resolve, reject) => {
      API.delete(id, (response) => {
        if (response.status !== 200) {
          if (response.status === 403) {
            reject(response.data.message);
            return;
          }

          reject(response.data.errors);
          return;
        }

        resolve("Success");
      });
    });
  },

  import({}, formData) {
    return new Promise((resolve, reject) => {
      API.import(formData, (response) => {
        if (response.status !== 200) {
          reject(response.data.errors);
          return;
        }

        resolve("Success");
      });
    });
  },

  export({}, mime) {
    return new Promise((resolve, reject) => {
      API.export(mime, {}, (response) => {
        if (response.status !== 200) {
          reject(response.data.errors);
          return;
        }

        resolve("Success");
      });
    });
  },
});

// Set mutation options.
export const setMutations = () => ({
  SETUP_FETCH_CONFIG(state, config) {
    for (const c in config) {
      state.fetch_config = {
        ...state.fetch_config,
        [c]: config[c],
      };
    }
  },

  SETUP_FETCH_CONFIG_HARD(state, config) {
    state.fetch_config = config;
  },

  SET_RECORDS(state, records) {
    state.records = records;
  },

  SET_RECORD(state, record) {
    state.record = record;
  },

  SET_META(state, meta) {
    state.meta = meta;
  },
});
