<template>
  <div class="d-flex flex-column flex-lg-row-fluid py-10">
    <div class="d-flex flex-center flex-column flex-column-fluid">
      <div class="w-lg-500px p-10 p-lg-15 mx-auto">
        <LoginForm />
      </div>
    </div>

    <Footer class="pb-0" />
  </div>
</template>

<script>
import LoginForm from "@/components/forms/LoginForm";

export default {
  components: {
    LoginForm,
  },
};
</script>
