import _ from "lodash";
import axios from "axios";
import store from "@/store";

class Auth {
  constructor() {
    this.SESSION = window.localStorage;
    this.API_URL = process.env.VUE_APP_API_URL;
  }

  /**
   * Get axios request headers.
   *
   */
  getHeaders = (token) => ({
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  });

  /**
   * Check if user is authenticate.
   *
   * @returns {boolean}
   */
  authenticate() {
    const token = this.SESSION.getItem("token") || null;

    return new Promise((resolve, reject) => {
      // Break if no token exists.
      if (!token) {
        reject(false);
      }

      // If user is not in state, try to reload him.
      if (_.isEmpty(store.getters.authUser)) {
        axios
          .get(`${this.API_URL}/me`, { headers: this.getHeaders(token) })
          .then((response) => {
            // Set user record in store.
            store.dispatch("setAuthUser", response.data.data);
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      } else {
        // User object exists.
        resolve(true);
      }
    });
  }

  /**
   * Handling direct login on API.
   *
   * @param {object} form
   * @returns {boolean}
   */
  handleLogin(form) {
    // Remove old token.
    this.SESSION.removeItem("token");

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/auth/log-in`, form, {
          headers: this.getHeaders(null),
        })
        .then((response) => {
          // Log in passed save fresh token to storage.
          this.SESSION.setItem("token", response.data.token);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  handleLogout() {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.API_URL}/auth/log-out`, {
          headers: this.getHeaders(this.SESSION.getItem("token")),
        })
        .then((response) => {
          this.SESSION.removeItem("token");
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  token() {
    return store;
  }
}

export default Auth;
