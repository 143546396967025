<template>
  <div>
    <SettingsForm :user="authUser" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SettingsForm from "@/components/forms/SettingsForm";

export default {
  components: {
    SettingsForm,
  },

  computed: {
    ...mapGetters({
      authUser: "authUser",
    }),
  },
};
</script>

<style></style>
