import { createStore } from "vuex";

import system from "./modules/system";
import dashboard from "./modules/dashboard";
import user from "./modules/user";
import categories from "./modules/categories";
import brands from "./modules/brands";
import stores from "./modules/stores";
import transactions from "./modules/transactions";
import invoices from "./modules/invoices";
import confirmed_bills from "./modules/confirmed_bills";

export default createStore({
  modules: {
    system,
    dashboard,
    user,
    categories,
    brands,
    stores,
    transactions,
    invoices,
    confirmed_bills,
  },
});
