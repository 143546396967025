import { importGlobalComponents, getGlobalProperties } from "./libs/appHelper";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/assets/scss/style.scss";
import VueFeather from "vue-feather";
import vClickOutside from "click-outside-vue3";
import axios from "axios";
import VueAxios from "vue-axios";
import config from "@/configs";
import { interceptAxios } from "@/interceptors/axios";
import Toaster from "@meforma/vue-toaster";
import VueApexCharts from "vue3-apexcharts";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import Popper from "vue3-popper";

import DefaultLayout from "@/layouts/Default";
import AuthLayout from "@/layouts/Auth";

import mitt from "mitt";
const emitter = mitt();

// Create the app instance.
const app = createApp(App);

interceptAxios();

// Import external libs and packages.
app.use(VueAxios, axios);
app.use(Toaster);
app.use(VueApexCharts);

app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "places",
  },
});

app.use(vClickOutside);
app.component(VueFeather.name, VueFeather);
app.component("Datepicker", Datepicker);
app.component("Multiselect", Multiselect);
app.component("Popper", Popper);

// Import app layouts.
app.component("default-layout", DefaultLayout);
app.component("auth-layout", AuthLayout);

// Import components as global from subfolders.
importGlobalComponents(["base", "global", "modals", "filters"]).forEach((c) => {
  app.component(c.name, c.path);
});

// Set globally acessible properties.
getGlobalProperties().forEach((p) => {
  app.config.globalProperties[p.key] = p.value;
});

// Config file global import.
app.config.globalProperties.$config = config;

// Event bus emmiter.
app.config.globalProperties.$eventBus = emitter;

// Build the app with store and router.
app.use(store).use(router).mount("#app");
