<template>
  <div class="row g-5 g-xl-8">
    <WidgetLinks class="col-12" />

    <WidgetCounters class="col-12 mt-0" />

    <div class="col-12">
      <div class="card mb-5 mb-xxl-8">
        <div class="card-body pb-4">
          <AreaChart
            :setup="transactionsGraph"
            :loading="graph_loading"
            title="Transactions"
            subtitle="Sum (Σ) of transactions count."
            @dateChanged="fetchTransactionsGraph"
          />
        </div>

        <div class="card-body pb-4 pt-0">
          <Table
            v-if="table_refresh_key > 0"
            :config="table_config"
            :key="table_refresh_key"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AreaChart from "@/components/charts/AreaChart";
import WidgetLinks from "@/components/pages/home/WidgetLinks";
import WidgetCounters from "@/components/pages/home/WidgetCounters";

export default {
  components: {
    AreaChart,
    WidgetLinks,
    WidgetCounters,
  },

  computed: {
    ...mapGetters({
      transactionsGraph: "dashboard/transactionsGraph",
    }),
  },

  mounted() {
    this.fetchTransactionsGraph(null);
  },

  beforeUnmount() {
    this.$store.dispatch(
      `transactions/setupConfigHard`,
      this.$config.transactions.fetch
    );
  },

  data: () => ({
    graph_loading: false,
    table_refresh_key: 0,
    table_config: {},
  }),

  methods: {
    fetchTransactionsGraph(date) {
      const dateRange = date || this.$dt.defaultGraphRange(30);
      this.graph_loading = true;

      this.$store
        .dispatch("dashboard/fetchWidgetData", {
          type: "graph",
          date: dateRange,
        })
        .then(() => (this.graph_loading = false));

      // Set up date range in table component.
      this.table_config = {
        ...this.$config.transactions,
        fetch: {
          ...this.$config.transactions.fetch,
          created_at: [
            { start: this.$dt.getISODate(dateRange[0]) },
            { end: this.$dt.getISODate(dateRange[1]) },
          ],
        },
      };

      this.table_refresh_key = this.table_refresh_key + 1;
    },
  },
};
</script>
