import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

import Auth from "@/middlewares/auth";

import Home from "@/views/Home";

import List from "@/views/stores";
import Brands from "@/views/stores/brands";
import Transactions from "@/views/transactions";
import Invoices from "@/views/invoices";
import Settings from "@/views/settings";
import ConfirmedBills from "@/views/confirmed_bills";

import LogIn from "@/views/auth/LogIn";

const AUTH = new Auth();

const routes = [
  {
    path: "/brands",
    name: "brands",
    component: Brands,
  },
  {
    path: "/",
    name: "dashboard",
    component: Home,
  },
  {
    path: "/stores",
    name: "stores",
    component: List,
  },
  {
    path: "/transactions",
    name: "transactions",
    component: Transactions,
  },
  {
    path: "/invoices",
    name: "invoices",
    component: Invoices,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
  },
  {
    path: "/confirmed_bills",
    name: "confirmed_bills",
    component: ConfirmedBills,
  },
  {
    path: "/log-in",
    name: "login",
    component: LogIn,
    meta: {
      layout: "auth",
      noAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.noAuth || false) {
    // Show current page since auth is not required.
    store.dispatch("appHasLoaded");
    next();
  } else {
    // Run through authenticated methods.
    AUTH.authenticate()
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          path: "/log-in",
        });
      })
      .then(() => {
        setTimeout(() => {
          store.dispatch("appHasLoaded");
        }, 500);
      });
  }
});

export default router;
