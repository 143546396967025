/*eslint no-empty-pattern: "off"*/

import API from "@/api/system";

// Initial state
const state = {
  app_loading: true,

  side_menu_links: [
    {
      path: "/",
      title: "Dashboard",
      name: "dashboard",
    },
    {
      path: "/brands",
      title: "Brands",
      name: "brands",
    },
    {
      path: "/stores",
      title: "Stores",
      name: "stores",
    },
    {
      path: "/transactions",
      title: "Transactions",
      name: "transactions",
    },
    {
      path: "/invoices",
      title: "Invoices",
      name: "invoices",
    },
    {
      path: "/settings",
      title: "Settings",
      name: "settings",
    },
    {
      path: "/confirmed_bills",
      title: "Confirmed Bills",
      name: "confirmed_bills",
    },
  ],
  // Side menu opened/closed state on devices less then 991px.
  aside_opened: false,
};

// Getters
const getters = {
  appLoading: (state) => state.app_loading,
  sideMenuLinks: (state) => state.side_menu_links,
  asideOpened: (state) => state.aside_opened,
};

const actions = {
  appHasLoaded({ commit }) {
    commit("APP_HAS_LOADED");
  },

  toggleCollapseSideMenu({ commit }, index) {
    commit("TOGGLE_SIDE_MENU_COLLAPSE", index);
  },

  toggleSideMenu({ commit }) {
    commit("TOGGLE_ASIDE_OPENED");
  },

  fetchAmountRanges({}, model) {
    return new Promise((resolve, reject) => {
      API.fetch({ modelName: model }, (response) => {
        if (response.status !== 200) {
          // #TODO - Handle errors
          console.log("Error", response);
          reject("Fail");
          return;
        }

        resolve(response.data);
      });
    });
  },
};

const mutations = {
  APP_HAS_LOADED(state) {
    state.app_loading = false;
  },

  TOGGLE_SIDE_MENU_COLLAPSE(state, index) {
    // Toggle not set allready.
    if (state.side_menu_links[index].collapsed === undefined) {
      state.side_menu_links[index].collapsed = true;
      return;
    }

    state.side_menu_links[index].collapsed =
      !state.side_menu_links[index].collapsed;
  },

  TOGGLE_ASIDE_OPENED(state) {
    state.aside_opened = !state.aside_opened;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
