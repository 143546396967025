/*eslint no-unused-vars: "off"*/

import dashboard_api from "@/api/dashboard";

// Initial state
const state = {
  transactions_graph: {
    labels: [],
    series: [],
  },
};

// Getters
const getters = {
  transactionsGraph: (state) => state.transactions_graph,
};

const actions = {
  fetchWidgetData({ commit }, params) {
    return new Promise((resolve, reject) => {
      dashboard_api.fetchWidgetData(params, (response) => {
        if (response.status !== 200) {
          reject("Fail");
          return;
        }

        commit("SET_TRANSACTIONS_GRAPH", response.data);
        resolve("Success");
      });
    });
  },

  fetchWidgetCounts({ commit }, params) {
    return new Promise((resolve, reject) => {
      dashboard_api.fetchWidgetCounts(params, (response) => {
        if (response.status !== 200) {
          reject("Fail");
          return;
        }

        resolve(response.data);
      });
    });
  },
};

const mutations = {
  SET_TRANSACTIONS_GRAPH(state, values) {
    state.transactions_graph = {
      labels: values.map((value) => value.label),
      series: [
        {
          name: "Charge",
          data: values.map((value) => value.charge),
        },
        {
          name: "Cashback",
          data: values.map((value) => value.cashback),
        },
      ],
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
